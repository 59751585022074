import React, {Component} from 'react';
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";

export class Credit_Funds extends Component {

    constructor() {
        super();
        this.state = {
            cards: [
                {label: 'Select Card', value: null},
                {label: 'Card 1', value: '1'},
                {label: 'Card 2', value: '2'}
            ],
            card: null,
        };
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <h2 className="mb-20">Credit_Funds</h2>
                    </div>
                </div>
                <div className="p-col-4 p-offset-4">
                    <div className="left-side_template">
                        <div className="credit--fund-icons">
                            <ul className="clearfix">
                                <li>
                                    <div className="card">
                                        <img src="assets/ewallet/img/bank-building.png" className="center-card-img"/>
                                        <a className="ta-center">Credit Comissions</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <div className="p-grid dashboard">
                        <div className="p-col-12 p-md-6 p-lg-6">
                            <div className="card your-balance_card">
                                <h2 className="mb-20">Credit Comissions</h2>
                                <div className="p-grid p-fluid">
                                    <div className="p-col-12 p-md-4">
                                        From corporate account*
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText placeholder="From corporate account"/>
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        To my card(s)*
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <Dropdown options={this.state.cards} value={this.state.card} onChange={event => this.setState({card: event.value})} autoWidth={false} />
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        My card balance
                                    </div>
                                    <div className="p-col-12 p-md-8">
                                        <InputText placeholder="0.00"/>
                                    </div>

                                    <div className="p-col-12 p-md-4">
                                        Amount to Transfer*
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText placeholder="Amount"/>
                                    </div>
                                    <div className="p-col-12 p-md-4">
                                        <InputText placeholder="USD"/>
                                    </div>

                                    <div className="p-col-12 p-md-8 p-offset-4">
                                        <Button label="Search" style={{marginTop: 15}} />
                                    </div>
                                    <div className="p-col-12 p-md-12">
                                        <p className="form-lastinfo" style={{fontWeight: '100'}}>
                                            * Gloval Unbanked is not responsible for any fees incurred by your financial institution.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-6">
                            <div className="card your-balance_card">
                                <h2 className="mb-20">Transactions</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
