import React, {Component} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";

export class Cards extends Component {

    constructor() {
        super();
        this.state = {
            cards:  [
                {label: 'label 1', name: 'Name1', balance: '100', isVirtual: 'true', account: '1', status: 'Active', created: '10-11-2020', reset: 'false'},
                {label: 'label 2', name: 'Name2', balance: '95', isVirtual: 'true', account: '2', status: 'Active', created: '11-11-2020', reset: 'false'},
                {label: 'label 3', name: 'Name3', balance: '90', isVirtual: 'true', account: '3', status: 'Active', created: '12-11-2020', reset: 'false'}
            ],
        };
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <div className="card card-w-title datatable-demo">
                        <h2 className="mb-20">Cards</h2>
                        <DataTable value={this.state.cards} selectionMode="single" paginator={true} rows={10}
                                   responsive={true}>
                            <Column field='label' header='Label'/>
                            <Column field='name' header='Name'/>
                            <Column field='balance' header='Balance'/>
                            <Column field='isVirtual' header='Virtual'/>
                            <Column field='account' header='Account'/>
                            <Column field='status' header='Status'/>
                            <Column field='created' header='Created'/>
                            <Column field='reset' header='Reset'/>
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}
