import React, { Component } from 'react';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppInlineProfile} from './AppInlineProfile';
import {AppFooter} from './AppFooter';
import {AppMenu} from './AppMenu';
import {AppConfig} from './AppConfig';
import { Route, Redirect } from 'react-router-dom';
import { withRouter }    from 'react-router'
import {Cards_Dashboard} from "./components/Cards_Dashboard";
import {KYC} from "./components/KYC";
import {Load_Money} from "./components/Load_Money";
import {Received_Messages} from "./components/Messages";
import {Faq} from "./components/Faq";
import {Cards} from "./components/Cards";
import {Transactions} from "./components/Transactions";
import {Apply_for_Card} from "./components/Apply_For_Card";
import {Credit_Funds} from "./components/Credit_Funds";
import {Reset_PIN} from "./components/Reset_PIN";
import {Active_Card} from "./components/Activate_Card";
import {Transfer_To_Companion} from "./components/Transfer_To_Companion";
import {Register_Card} from "./components/Register_Card";

import {Dashboard} from './components/Dashboard';
import {DashboardBanking} from './components/DashboardBanking';
import {FormsDemo} from './components/FormsDemo';
import {SampleDemo} from './components/SampleDemo';
import {DataDemo} from './components/DataDemo';
import {PanelsDemo} from './components/PanelsDemo';
import {OverlaysDemo} from './components/OverlaysDemo';
import {MenusDemo} from './components/MenusDemo';
import {MessagesDemo} from './components/MessagesDemo';
import {ChartsDemo} from './components/ChartsDemo';
import {MiscDemo} from './components/MiscDemo';
import {EmptyPage} from './components/EmptyPage';
import {Documentation} from './components/Documentation';
import {UtilsDemo} from './components/UtilsDemo';
import {Help} from './pages/Help';
import {Invoice} from "./pages/Invoice";
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'font-awesome/css/font-awesome.css';
import 'primereact/resources/primereact.min.css';
import './App.css';
import {Profile} from "./components/profile";
import {changeStyleSheetUrl} from "./util/commonFunctions";

class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            profileMode: 'top',
            layoutCompact: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: true,
            menuActive: false,
            theme: 'ewallet',
            layout: 'ewallet',
            version: 'v4',
            configDialogActive: false,

            logout: false,
            usuarioSesion: null,
            login: false,
            gridObjeto: {},
            mostrarBandejaEnFooter: false,
            configObjecto: {},
            recibidosTopbar: 0,
            atendidosTopbar: 0,
            activeIndexBandeja: 0
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.changeMenuMode = this.changeMenuMode.bind(this);
        this.changeMenuColor = this.changeMenuColor.bind(this);
        this.changeProfileMode = this.changeProfileMode.bind(this);
        this.changeVersion = this.changeVersion.bind(this);
        this.changeLayout = this.changeLayout.bind(this);
        this.changeTheme = this.changeTheme.bind(this);
        this.onConfigButtonClick = this.onConfigButtonClick.bind(this);
        this.onConfigCloseClick = this.onConfigCloseClick.bind(this);
        this.onConfigClick = this.onConfigClick.bind(this);
        this.createMenu();

        this.onHandleClickProfile = this.onHandleClickProfile.bind(this);
        this.actualizarTotalTopbar = this.actualizarTotalTopbar.bind(this);
        this.actionToAccount = this.actionToAccount.bind(this);
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');
	}

	onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        }));

        if(this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }
        else {
            if(this.isDesktop())
                this.setState({staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive});
            else
                this.setState({staticMenuMobileActive: !this.state.staticMenuMobileActive});
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({topbarMenuActive: !this.state.topbarMenuActive});
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if(this.state.activeTopbarItem === event.item)
            this.setState({activeTopbarItem: null});
        else
            this.setState({activeTopbarItem: event.item});

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if(!event.item.items) {
            this.hideOverlayMenu();
        }
        if(!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

	onConfigButtonClick(event){
		this.configClick = true;
		this.setState({configDialogActive: !this.state.configDialogActive})
	}

	onConfigCloseClick(){
		this.setState({configDialogActive: false})
	}

	onConfigClick(){
		this.configClick = true;
	}

    onDocumentClick(event) {
        if(!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if(!this.menuClick) {
            if(this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

		if (!this.configClick) {
			this.setState({configDialogActive: false});
		}

        if(!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
		this.configClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

	changeMenuMode(event) {
		this.setState({layoutMode: event.layoutMode})
		if(event.layoutMode === 'horizontal') {
			this.setState({profileMode: 'top'})
		}
    }

	changeMenuColor(event) {
		this.setState({darkMenu: event.darkMenu})
	}

	changeProfileMode(event) {
		this.setState({profileMode: event.profileMode})
	}

	changeVersion(event) {
		this.setState({version:event.version});
		if(event.version === 'v3'){
			changeStyleSheetUrl('layout-css', this.state.layout, 'layout');
			changeStyleSheetUrl('theme-css', this.state.theme, 'theme');
		} else {
			changeStyleSheetUrl('layout-css', this.state.layout+'-v4', 'layout');
			changeStyleSheetUrl('theme-css', this.state.theme+'-v4', 'theme');
		}
	}

	changeLayout(event) {
		this.setState({layout: event.layout});
		if(this.state.version === 'v3'){
			changeStyleSheetUrl('layout-css', event.layout, 'layout');
		} else {
			changeStyleSheetUrl('layout-css', event.layout+'-v4', 'layout');
		}

		if(event.special) {
			this.setState({
				darkMenu: true
			})
		}
	}

    changeTheme(event) {
        this.setState({theme: event.theme});
        if(this.state.version === 'v3'){
            changeStyleSheetUrl('theme-css', event.theme, 'theme');
        } else {
            changeStyleSheetUrl('theme-css', event.theme+'-v4', 'theme');
        }
    }

    createMenu() {
        this.menu = [
            {label: 'Dashboard', icon: 'pi pi-home', to: '/' },
            {label: 'Transactions', icon: 'pi pi-users', to: '/transactions' },
            {label: 'Cards', icon: 'fa fa-money',
                items: [
                    {label: 'Cards', icon: 'pi pi-id-card', to: '/cards' },
                    {label: 'Apply for a Card', icon: 'pi pi-id-card', to: '/apply_for_Card' },
                    {label: 'Credit Funds', icon: 'pi pi-ticket', to: '/credit_Funds' },
                    {label: 'Reset PIN', icon: 'pi pi-sort-alt', to: '/reset_pin' },
                    {label: 'Activate Card', icon: 'pi pi-id-card', to: '/active_card' },
                    {label: 'Register Card', icon: 'pi pi-id-card', to: '/register_card' },
                ]
            },
            {label: 'KYC', icon: 'pi pi-id-card', to: '/kyc' },
            {label: 'Load Money', icon: 'pi pi-id-card', to: '/load_money' },
            {label: 'Transfer $ to Companion', icon: 'pi pi-dollar', to: '/transfer_to_companion' },

            {label: 'Messages', icon: 'pi pi-envelope', to: '/received_messages' },
            {label: 'FAQ', icon: 'pi pi-question-circle', to: '/faq' },
            {label: 'Logout', icon: 'fa fa-fw fa-sign-out', to: '/wallet' }
        ];
    }

    onHandleClickProfile = (e) =>  {
        this.props.history.push("/profile");
    }

    actualizarTotalTopbar(recibidosTopbar, atendidosTopbar){
        this.setState({
            recibidosTopbar,
            atendidosTopbar
        });
    }

    actionToAccount () {
        this.setState({opcionMenuSelected: 'Account'});
    }

    onLogout(){
        sessionStorage.clear();
        let logout = true;
        if (this !== undefined){
            if(this.handle){
                clearInterval(this.handle);
            }
            this.setState({
                logout
            });
        }
    }

    render() {
        if(JSON.parse(sessionStorage.getItem('login') == null)) {
            return (<Redirect to={`/wallet`}/>)
        }
        
        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });
        let menuClassName = classNames('layout-menu-container', {'layout-menu-dark': this.state.darkMenu});

        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <div>
                    <AppTopbar profileMode={this.state.profileMode} horizontal={this.isHorizontal()}
                               topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem}
                               onMenuButtonClick={this.onMenuButtonClick} onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                               onTopbarItemClick={this.onTopbarItemClick} onLogout={this.onLogout}
                               profile={{'userName': sessionStorage.getItem("userLogged"), 'area': ''}}
                               onHandleClickProfile={this.onHandleClickProfile}
                    />

                    <div className={menuClassName} onClick={this.onMenuClick}>
                        <div className="menu-scroll-content">
                            {(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal') &&
                            <AppInlineProfile onLogout={this.onLogout} profile={{'userName': sessionStorage.getItem("userLogged"), 'area': ''}}
                                              onHandleClickProfile={this.onHandleClickProfile}/>}
                            <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
                                     layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                        </div>
                    </div>

                    <div className="layout-main">
                        <Route path="/" exact component={Cards_Dashboard} />
                        <Route path="/kyc" exact component={KYC} />
                        <Route path="/load_money" exact component={Load_Money} />
                        <Route path="/received_messages" exact component={Received_Messages} />
                        <Route path="/faq" exact component={Faq} />
                        <Route path="/cards" exact component={Cards} />
                        <Route path="/transactions" exact component={Transactions} />
                        <Route path="/apply_for_Card" exact component={Apply_for_Card} />
                        <Route path="/credit_Funds" exact component={Credit_Funds} />
                        <Route path="/reset_pin" exact component={Reset_PIN} />
                        <Route path="/active_card" exact component={Active_Card} />
                        <Route path="/transfer_to_companion" exact component={Transfer_To_Companion} />
                        <Route path="/register_card" exact component={Register_Card} />
                        <Route path="/profile" exact component={Profile} />

                        <Route path="/dashboard_banking" exact component={DashboardBanking} />
                        <Route path="/forms" component={FormsDemo} />
                        <Route path="/sample" component={SampleDemo} />
                        <Route path="/data" component={DataDemo} />
                        <Route path="/panels" component={PanelsDemo} />
                        <Route path="/overlays" component={OverlaysDemo} />
                        <Route path="/menus" component={MenusDemo} />
                        <Route path="/messages" component={MessagesDemo} />
                        <Route path="/charts" component={ChartsDemo} />
                        <Route path="/misc" component={MiscDemo} />
                        <Route path="/empty" component={EmptyPage} />
                        <Route path="/utils" component={UtilsDemo} />
                        <Route path="/documentation" component={Documentation} />
						<Route path="/help" component={Help} />
						<Route path="/invoice" component={Invoice} />
                    </div>

                    <div className="layout-mask"></div>

					<AppConfig layoutMode={this.state.layoutMode} darkMenu={this.state.darkMenu} profileMode={this.state.profileMode}
							   version={this.state.version} layoutColor={this.state.layout} themeColor={this.state.theme}
							   changeMenuMode={this.changeMenuMode} changeMenuColor={this.changeMenuColor} changeProfileMode={this.changeProfileMode}
							   changeVersion={this.changeVersion} changeLayout={this.changeLayout} changeTheme={this.changeTheme}
							   onConfigButtonClick={this.onConfigButtonClick} onConfigCloseClick={this.onConfigCloseClick}
							   onConfigClick={this.onConfigClick} configDialogActive={this.state.configDialogActive}/>

                    <AppFooter />
                </div>
            </div>
        );
  }
}

export default withRouter(App);
