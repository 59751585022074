import React, {Component} from 'react';
import {Growl} from "primereact/growl";
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";

export class Transfer_To_Companion extends Component {

    constructor() {
        super();
        this.state = {
            cards: [
                {label: 'Select Card', value: null},
                {label: 'Card 1', value: '1'},
                {label: 'Card 2', value: '2'}
            ],
            cardFrom: null,
            cardTo: null,
            lstAmount: [
                {label: 'Select Amount', value: null},
                {label: '10', value: '10'},
                {label: '50', value: '50'},
                {label: '100', value: '100'}
            ],
            amount: null,
            lstCurrency: [
                {label: 'Select Currency', value: null},
                {label: 'USD', value: '1'},
                {label: 'MXN', value: '2'}
            ],
            currency: null,
            confirmationDisplay: false,
        };
    }

    sendRegisterCard = () => {
        this.props.history.push("/register_card");
    }

    render() {
        const dialogConfirmation = (
            <div>
                <Button icon="pi pi-check" onClick={() => this.setState({confirmationDisplay:false})} label="Yes" />
                <Button icon="pi pi-times" onClick={() => this.setState({confirmationDisplay:false})} label="No" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12 p-lg-12 p-xl-8">
                    <div className="card your-balance_card">
                        <h2 className="mb-20">Transfer $ to Companion Card</h2>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4 center-card">
                                From Card: *
                            </div>
                            <div className="p-col-12 p-md-8">
                                <Dropdown
                                    options={this.state.cards}
                                    value={this.state.cardFrom}
                                    onChange={event => this.setState({cardFrom: event.value})} autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <p>To Card:</p>
                                <a onClick={this.sendRegisterCard}> Register </a>
                            </div>
                            <div className="p-col-12 p-md-8">
                                <Dropdown
                                    options={this.state.cards}
                                    value={this.state.cardTo}
                                    onChange={event => this.setState({cardTo: event.value})} autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-4 center-card">
                                Amount: *
                            </div>
                            <div className="p-col-12 p-md-8">
                                <Dropdown
                                    options={this.state.lstAmount}
                                    value={this.state.amount}
                                    onChange={event => this.setState({amount: event.value})} autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-4 center-card">
                                Currency:
                            </div>
                            <div className="p-col-12 p-md-8">
                                <Dropdown
                                    options={this.state.lstCurrency}
                                    value={this.state.currency}
                                    onChange={event => this.setState({currency: event.value})} autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-8 p-offset-4 ta-right">
                                <Button label="Transfer" style={{marginTop: 15}} onClick={() => this.setState({confirmationDisplay: true})}/>
                            </div>
                            <Dialog header="Transfer confirmation" visible={this.state.confirmationDisplay} modal={true}
                                    style={{width: '50vw'}}
                                    footer={dialogConfirmation} onHide={() => this.setState({confirmationDisplay: false})}>
                                <p style={{color: '#292B2C'}}>Transfer card to card </p>
                            </Dialog>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
