import React, {Component} from 'react';

export class Cards_Dashboard extends Component {

    constructor() {
        super();
        this.state = {};
    }

    render() {
        return (
            <div className="p-grid dashboard">
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="card your-balance_card">
                        <h2 className="mb-20">Your Balance</h2>
                        <table className="custom_table">
                            <tbody>
                            <tr>
                                <td>USD WALLET</td>
                                <td className="ta-right">0 (USD)</td>
                            </tr>
                            <tr>
                                <td>EUR WALLET</td>
                                <td className="ta-right">0.00(EUR)</td>
                            </tr>

                            </tbody>
                        </table>


                        <table className="custom_table last-login-detail">
                            <tbody>
                            <tr>
                                <td>Last Login</td>
                                <td>11-18-2020</td>
                            </tr>
                            <tr>
                                <td>Time</td>
                                <td>05:57:23</td>
                            </tr>
                            <tr>
                                <td>IP:</td>
                                <td>201.137.172.221</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                {/* Headers
                <div className="p-col-12 p-md-6 p-lg-4">
                    <div className="card your-balance_card">
                        <h2 className="mb-20">Order New Card</h2>
                        <table className="custom_table">
                            <tbody>
                            <tr>
                                <td>Cost</td>
                                <td className="ta-right">0.0 (USD)</td>
                            </tr>
                            </tbody>
                        </table>
                        <div className="col-sm-2">
                            <a className="verify-btn" href="#">Order New Card</a>
                        </div>
                    </div>
                </div>
                */}
                <div className="p-col-12 p-md-6 p-lg-4">
                </div>
            </div>
        );
    }
}
