import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";

export class Transactions extends Component {

    constructor() {
        super();
        this.state = {
            cards: [
                {label: 'Select Card', value: null},
                {label: 'Card 1', value: '1'},
                {label: 'Card 2', value: '2'}
                ],
            card: null,
            transactions_cards:  [
                {refs: '1',description: 'desc 1',sender: 'Sender 1',receiver: 'Receiver 1',type: 'Type 1',amount: '1',status: '1',date: '01/12/2020'},
                {refs: '2',description: 'desc 1',sender: 'Sender 2',receiver: 'Receiver 2',type: 'Type 5',amount: '10',status: '2',date: '02/12/2020'},
                {refs: '3',description: 'desc 1',sender: 'Sender 3',receiver: 'Receiver 10',type: 'Type 10',amount: '100',status: '3',date: '03/12/2020'}
            ],
        };
    }

    render() {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h2 className="mb-20">Card data</h2>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-3">
                                Card*
                                <Dropdown options={this.state.cards} value={this.state.card} onChange={event => this.setState({card: event.value})} autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-3">
                                From:
                                <InputText placeholder="Email"/>
                            </div>
                            <div className="p-col-12 p-md-3">
                                To:
                                <InputText placeholder="Phone"/>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <Button label="Search" style={{marginTop: 15}} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h2 className="mb-20">Card Transaction History</h2>
                        <DataTable value={this.state.transactions_cards} selectionMode="single" paginator={true} rows={10}
                                   responsive={true}>
                            <Column field='refs' header='Refs #'/>
                            <Column field='description' header='Description'/>
                            <Column field='sender' header='Sender'/>
                            <Column field='receiver' header='Receiver'/>
                            <Column field='type' header='Type'/>
                            <Column field='amount' header='Amount'/>
                            <Column field='status' header='Status'/>
                            <Column field='date' header='Date'/>
                        </DataTable>
                    </div>
                </div>
            </div>
        );
    }
}
