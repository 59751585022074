import React, {Component} from 'react';
import {Growl} from "primereact/growl";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";

export class Register_Card extends Component {

    constructor() {
        super();
        this.state = {
            confirmationDisplay: false,
        };
    }

    render() {
        const dialogConfirmation = (
            <div>
                <Button icon="pi pi-check" onClick={() => this.setState({confirmationDisplay:false})} label="Yes" />
                <Button icon="pi pi-times" onClick={() => this.setState({confirmationDisplay:false})} label="No" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="p-col-12 p-lg-12 p-xl-8">
                    <div className="card your-balance_card">
                        <h1>Register Card</h1>
                        <div className="p-grid p-fluid">
                            <div className="p-col-12 p-md-4 center-card">
                                Card number: *
                            </div>
                            <div className="p-col-12 p-md-8">
                                <InputText placeholder="Card number"/>
                            </div>
                            <div className="p-col-12 p-md-4 center-card">
                                Cardholder Name: *
                            </div>
                            <div className="p-col-12 p-md-8">
                                <InputText placeholder="Cardholder Name"/>
                            </div>
                            <div className="p-col-12 p-md-4 center-card">
                                Alias: *
                            </div>
                            <div className="p-col-12 p-md-8">
                                <InputText placeholder="Alias"/>
                            </div>
                            <div className="p-col-12 p-md-8 p-offset-4 ta-right">
                                <Button label="Register" style={{marginTop: 15}} onClick={() => this.setState({confirmationDisplay: true})}/>
                                <Dialog header="Register Card confirmation" visible={this.state.confirmationDisplay} modal={true}
                                        style={{width: '50vw'}}
                                        footer={dialogConfirmation} onHide={() => this.setState({confirmationDisplay: false})}>
                                    <p style={{color: '#292B2C'}}>Register card </p>
                                </Dialog>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
