import React, {Component} from 'react';
import {Panel} from "primereact/components/panel/Panel";

export class Received_Messages extends Component {

    constructor() {
        super();
        this.state = {};
    }

    render() {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-md-12 p-lg-12 dashboard-banking">
                    <Panel className="messages" header={<div className="panel-heading-title">Direct messages</div>}>
                        <div className="p-grid-row">
                            <div className="message-box">
                                <div className="message">
                                    <img src="assets/layout/images/dashboard-banking/profile.png"
                                         alt="avalon-layout"
                                         className="messager-img"/>

                                    <div className="messager">Joshua Williams
                                        <i className="fa fa-circle online"></i>
                                        <span className="date pull-right">Today</span>
                                    </div>

                                    <div className="message-text">Paenitet me quod tu me rogas? Oh, sic, qui stultus
                                        plastic continentis rogavi te ut emas. Vides non manducare acidum
                                        hydrofluoric per plastic.
                                    </div>
                                </div>

                                <div className="clearfix"></div>
                            </div>

                            <div className="message-box">
                                <div className="message">
                                    <img src="assets/layout/images/dashboard-banking/profile1.png"
                                         alt="avalon-layout"
                                         className="messager-img"/>

                                    <div className="messager">Adelle Charles
                                        <i className="fa fa-circle offline"></i>
                                        <span className="date pull-right">Today</span>
                                    </div>

                                    <div className="message-text">Quinquaginta septem est - pars tua, triginta
                                        quinque millia. Est autem extra plus quindecim, tota tua est, quom
                                        meruisset.
                                    </div>
                                </div>

                                <div className="clearfix"></div>
                            </div>

                            <div className="message-box">
                                <div className="message">
                                    <img src="assets/layout/images/dashboard-banking/profile2.png"
                                         alt="avalon-layout"
                                         className="messager-img"/>

                                    <div className="messager">Marcos Moralez
                                        <i className="fa fa-circle online"></i>
                                        <span className="date pull-right">Yesterday</span>
                                    </div>

                                    <div className="message-text">Fac nos fecit. SIC.</div>
                                </div>

                                <div className="clearfix"></div>
                            </div>

                            <div className="message-box">
                                <div className="message">
                                    <img src="assets/layout/images/dashboard-banking/profile3.png"
                                         alt="avalon-layout"
                                         className="messager-img"/>

                                    <div className="messager">Matt Litherland
                                        <i className="fa fa-circle online"></i>
                                        <span className="date pull-right">3 days ago</span>
                                    </div>

                                    <div className="message-text">Puto quia una res potest - venimus in cognitionem.
                                        Vide pretium in manibus.
                                    </div>
                                </div>

                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </Panel>
                </div>
                <div className="p-col-12 p-md-12 p-lg-12 dashboard-banking">
                    <Panel className="messages" header={<div className="panel-heading-title">Global messages</div>}>
                        <div className="p-grid-row">
                            <div className="message-box">
                                <div className="message">
                                    <img src="assets/layout/images/dashboard-banking/profile.png"
                                         alt="avalon-layout"
                                         className="messager-img"/>

                                    <div className="messager">Joshua Williams
                                        <i className="fa fa-circle online"></i>
                                        <span className="date pull-right">Today</span>
                                    </div>

                                    <div className="message-text">Paenitet me quod tu me rogas? Oh, sic, qui stultus
                                        plastic continentis rogavi te ut emas. Vides non manducare acidum
                                        hydrofluoric per plastic.
                                    </div>
                                </div>

                                <div className="clearfix"></div>
                            </div>

                            <div className="message-box">
                                <div className="message">
                                    <img src="assets/layout/images/dashboard-banking/profile1.png"
                                         alt="avalon-layout"
                                         className="messager-img"/>

                                    <div className="messager">Adelle Charles
                                        <i className="fa fa-circle offline"></i>
                                        <span className="date pull-right">Today</span>
                                    </div>

                                    <div className="message-text">Quinquaginta septem est - pars tua, triginta
                                        quinque millia. Est autem extra plus quindecim, tota tua est, quom
                                        meruisset.
                                    </div>
                                </div>

                                <div className="clearfix"></div>
                            </div>

                            <div className="message-box">
                                <div className="message">
                                    <img src="assets/layout/images/dashboard-banking/profile2.png"
                                         alt="avalon-layout"
                                         className="messager-img"/>

                                    <div className="messager">Marcos Moralez
                                        <i className="fa fa-circle online"></i>
                                        <span className="date pull-right">Yesterday</span>
                                    </div>

                                    <div className="message-text">Fac nos fecit. SIC.</div>
                                </div>

                                <div className="clearfix"></div>
                            </div>

                            <div className="message-box">
                                <div className="message">
                                    <img src="assets/layout/images/dashboard-banking/profile3.png"
                                         alt="avalon-layout"
                                         className="messager-img"/>

                                    <div className="messager">Matt Litherland
                                        <i className="fa fa-circle online"></i>
                                        <span className="date pull-right">3 days ago</span>
                                    </div>

                                    <div className="message-text">Puto quia una res potest - venimus in cognitionem.
                                        Vide pretium in manibus.
                                    </div>
                                </div>

                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </Panel>
                </div>
            </div>
        );
    }
}
