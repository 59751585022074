import React, {Component} from 'react';
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";

export class Reset_PIN extends Component {

    constructor() {
        super();
        this.state = {
            cards: [
                {label: 'Select Card', value: null},
                {label: 'Card 1', value: '1'},
                {label: 'Card 2', value: '2'}
            ],
            card: null,
            resetPINDisplay: false,
        };
    }

    render() {
        const dialogFooterPIN = (
            <div>
                <Button icon="pi pi-check" onClick={() => this.setState({resetPINDisplay:false})} label="Yes" />
                <Button icon="pi pi-times" onClick={() => this.setState({resetPINDisplay:false})} label="No" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h2 className="mb-20">Reset PIN</h2>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-3">
                                Card*
                                <Dropdown options={this.state.cards} value={this.state.card} onChange={event => this.setState({card: event.value})} autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-3">
                                <Dialog header="Reset confirmation" visible={this.state.resetPINDisplay} modal={true}
                                        style={{width: '50vw'}}
                                        footer={dialogFooterPIN} onHide={() => this.setState({resetPINDisplay: false})}>
                                    <p style={{color: '#292B2C'}}>Reset PIN Confirm</p>
                                </Dialog>
                                <Button label="Reset PIN" style={{marginTop: 15}}
                                        onClick={() => this.setState({resetPINDisplay: true})}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
