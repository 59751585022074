import React, {Component} from 'react';

import {TabMenu} from "primereact/tabmenu";

import {changeStyleSheetUrl} from "../util/commonFunctions";

export default class WalletInternational extends Component {
    constructor(props) {
        super(props);

        this.items =  [
            {label: 'Home', icon: 'pi pi-fw pi-home'},
            {label: 'About Us', icon: 'pi pi-fw pi-calendar'},
            {label: 'Statistics', icon: 'pi pi-fw pi-pencil'},
            {label: 'Operational Capabilities', icon: 'pi pi-fw pi-file'}
        ];
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');
    }

    sendWallet = () => {
        this.props.history.push("/wallet");
    }

    render() {
        return (
            <div className="p-grid">
                <div className="p-col-12" id="header" style={{paddingBottom: 0}}>
                    <div className="p-grid card" style={{marginBottom: 0}}>
                        <div className="p-col-2" style={{minWidth: 140}}>
                            <img src="assets/ewallet/img/logo.png" onClick={this.sendWallet} className="hand-icon"/>
                        </div>
                        <div className="p-col-10 ta-right" style={{minWidth: 300}}>
                            <div style={{paddingTop: 20}}>
                                <a href="#/signUp" className="wallet_a">Sign Up</a>
                                <a href="#/login" className="wallet_a">Demo Login</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 banner" id="main_E-Wallet_International">
                    <div className="p-grid">
                        <div className="p-col-5">
                            <div>
                                <img src="assets/ewallet/img/gold-credit-card.gif" className="img-responsive2"/>
                            </div>
                        </div>
                        <div className="p-col-6">
                            <h1>E-Wallet International – The Everywhere Payment System</h1>
                            <p>Load any funds. Save as Dollars, Euros and more. Send money to friends and family.
                                Make purchases online and in stores. With E-Wallet International, you have the power of VISA,
                                MasterCard and UnionPay, without the need for a bank account. It’s your wallet in the cloud.</p>
                            {/* Headers
                            <a href="#">Learn More</a>
                            */}
                        </div>
                    </div>
                </div>

                <div className="p-col-12 card padd-40" id="Why_E-Wallet_International" style={{marginBottom: 0}}>
                    <div className="p-grid">
                        <div className="p-col-12 ta-center">
                            <h1>Why E-Wallet International Card Services?</h1>
                        </div>

                        <div className="p-col-4">
                            <h4>Satisfying Clients’ Needs</h4>
                            <p>Practical and innovative services. Reliable 24x7 systems. Serious customer support.
                                Making sure your customers get what they need from you is how we make sure you get what you need from us.</p>
                            <hr/>
                            <h4>Fast Start-Up</h4>
                            <p>Delay is death in business. With our easy onboarding process and dedicated due diligence team,
                                your company can be up and running with your new card program in just a few weeks.</p>
                            <hr/>
                            <h4>Dynamic Development</h4>
                            <p>Custom card designs. Custom interfaces. Custom payment solutions. Custom integration.
                                Our IT and support teams make sure that your company projects the image you want, with the services you want.</p>
                        </div>

                        <div className="p-col-4 center-card">
                            <img src="assets/ewallet/img/credit-card.gif" className="center-card-img"
                                 style={{height: 200}}/>
                        </div>

                        <div className="p-col-4">
                            <h4>Full Turnkey Solution</h4>
                            <p>Our solution encompasses the whole
                                package for your company roll-out and
                                avoids the hassle of integrations and IT
                                delays. Your white-label solution has
                                Website, Customer Control Panel, System
                                Administration Panel and complete
                                payment solutions. And you can still ask
                                for additional customization, and we will
                                do it for you.</p>
                            <hr/>
                            <h4>Strong Branding</h4>
                            <p>We are a private-label, B2B-only solution focused on strengthening your company.
                                Your customers see your brand, not ours. We don’t offer any services to end-users;
                                we leave that to you.</p>
                            <hr/>
                            <h4>New Revenues</h4>
                            <p>Cards can both add to your offerings and tie into your existing products and services.
                                And you can generate service fees while enhancing the customer experience. </p>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 card padd-40" id="Cards_Benefits" style={{marginBottom: 0}}>
                    <div className="p-grid">
                        <div className="p-col-12 ta-center">
                            <h1>Cards Benefits</h1>
                        </div>

                        <div className="p-col-3">
                            <img src="assets/ewallet/img/card-banifit1.png" className="center-card-img"/>
                            <h4 className="ta-center">Instant global payments</h4>
                            <p>The service enables all your customers to do instant transfer of funds anywhere in the world.</p>
                        </div>
                        <div className="p-col-3">
                            <img src="assets/ewallet/img/card-banifit2.png" className="center-card-img"/>
                            <h4 className="ta-center">Cards accepted worldwide</h4>
                            <p>Our cards can be used in more than 210 countries.</p>
                        </div>
                        <div className="p-col-3">
                            <img src="assets/ewallet/img/card-banifit3.png" className="center-card-img"/>
                            <h4 className="ta-center">No credit check needed</h4>
                            <p>Since  the cards customers are the members of your company, there will be no credit check for the ends uses.</p>
                        </div>
                        <div className="p-col-3">
                            <img src="assets/ewallet/img/card-banifit4.png" className="center-card-img"/>
                            <h4 className="ta-center">Access system from anywhere</h4>
                            <p>Accessing your merchant account is as easy as sending an email.  You only need Internet.</p>
                        </div>

                        <div className="p-col-12" style={{marginTop:50}}>
                        </div>

                        <div className="p-col-3">
                            <img src="assets/ewallet/img/card-banifit5.png" className="center-card-img"/>
                            <h4 className="ta-center">Manage & track expenses</h4>
                            <p>Every transaction made with the card is recorded in our servers for future search and cash Flow management.</p>
                        </div>
                        <div className="p-col-3">
                            <img src="assets/ewallet/img/card-banifit6.png" className="center-card-img"/>
                            <h4 className="ta-center">Multiple currencies</h4>
                            <p>Due to our different BIN’s the cards can issued in multiple currencies.</p>
                        </div>
                        <div className="p-col-3">
                            <img src="assets/ewallet/img/card-banifit7.png" className="center-card-img"/>
                            <h4 className="ta-center">SMS & APP services</h4>
                            <p>The platform has SMS alert services for you to know a transaction has been made.
                                Transaction validations are now done through your phone.</p>
                        </div>
                        <div className="p-col-3">
                            <img src="assets/ewallet/img/card-banifit8.png" className="center-card-img"/>
                            <h4 className="ta-center">3D-Secure MasterCard</h4>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 reduce-cost-with-e-wallet padd-40" id="Reduce_costs_with_E-wallet">
                        <div className="p-grid">
                          <div className="p-col-5 p-offset-7">
                              <h4>Reduce costs with E-Wallet</h4>
                                <ul>
                                    <li><i className="pi pi-chevron-right"></i> Reduce cash handling in your company</li>
                                    <li><i className="pi pi-chevron-right"></i> Reduce costs of issuing checks</li>
                                    <li><i className="pi pi-chevron-right"></i> Pay commissions, wages, and bonuses in seconds.</li>
                                    <li><i className="pi pi-chevron-right"></i> Access your funds anytime through the internet.</li>
                                </ul>
                            </div>
                        </div>
                </div>

                <div className="p-col-12 promioting-business padd-40" id="Promioting_Business" >
                    <div className="p-grid">
                        <div className="p-col-6 p-offset-1">
                            <h4>Promoting your business</h4>
                            <p>The cards issued for your company will be personalized to transmit your business image to all your customers.</p>
                            {/* Headers
                            <a>Learn More</a>
                            */}
                        </div>

                        <div className="p-col-5"></div>
                    </div>
                </div>

                <div className="p-col-12 income-from-card padd-40" id="Businesses_will_benefit">
                    <div className="p-grid">
                        <div className="p-col-6 p-offset-6">
                            <h2>Businesses will benefit using our card services by creating another revenue stream through the following options:</h2>
                            <div className="p-grid mt-10">
                                <div className="p-col-6">
                                    <ul>
                                        <li><i className="pi pi-chevron-right"></i>Sale of cards</li>
                                        <li><i className="pi pi-chevron-right"></i>Upload fee</li>
                                        <li><i className="pi pi-chevron-right"></i>Monthly fee</li>
                                        <li><i className="pi pi-chevron-right"></i>ATM transactions</li>
                                    </ul>
                                </div>
                                <div className="p-col-6">
                                    <ul>
                                        <li><i className="pi pi-chevron-right"></i>POS transactions</li>
                                        <li><i className="pi pi-chevron-right"></i>Online payments</li>
                                        <li><i className="pi pi-chevron-right"></i>FX fees</li>
                                        <li><i className="pi pi-chevron-right"></i>Card to card transfer</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 card" id="Operational_Capabilities padd-40" style={{marginBottom: 0}}>
                    <div className="p-grid">
                        <div className="p-col-12 ta-center">
                            <h1>Operational Capabilities</h1>
                        </div>

                        <div className="p-col-4">
                            <img src="assets/ewallet/img/operational1.png" className="center-card-img"/>
                            <h4 className="ta-center">Card Management System</h4>
                            <ul className="ml-40">
                                <li><i className="pi pi-chevron-right"></i> Registration</li>
                                <li><i className="pi pi-chevron-right"></i> Verification</li>
                                <li><i className="pi pi-chevron-right"></i> Card issuance</li>
                                <li><i className="pi pi-chevron-right"></i> Customer profile management</li>
                                <li><i className="pi pi-chevron-right"></i> Card data management</li>
                                <li><i className="pi pi-chevron-right"></i> Administration</li>
                                <li><i className="pi pi-chevron-right"></i> Reporting</li>
                            </ul>
                        </div>
                        <div className="p-col-4">
                            <img src="assets/ewallet/img/operational2.png" className="center-card-img"/>
                            <h4 className="ta-center">Client Services</h4>
                            <ul className="ml-40">
                                <li><i className="pi pi-chevron-right"></i> Customer support 24 x 7 x 365</li>
                                <li><i className="pi pi-chevron-right"></i> Non critical issues resolved in less than an hour</li>
                                <li><i className="pi pi-chevron-right"></i> Access to live agents and talk to real people</li>
                                <li><i className="pi pi-chevron-right"></i> Our support team will resolve things for you</li>
                            </ul>
                        </div>
                        <div className="p-col-4">
                            <img src="assets/ewallet/img/operational3.png" className="center-card-img"/>
                            <h4 className="ta-center">Cardholder Account Management Systems</h4>
                            <ul className="ml-40">
                                <li><i className="pi pi-chevron-right"></i> View transactions made in real time</li>
                                <li><i className="pi pi-chevron-right"></i> Reset PIN in case you forgot</li>
                                <li><i className="pi pi-chevron-right"></i> Replacement of cards in case it was lost</li>
                                <li><i className="pi pi-chevron-right"></i> Transfer funds to companion cards</li>
                            </ul>
                        </div>

                        <div className="p-col-12" style={{marginTop:50}}>
                        </div>

                        <div className="p-col-4">
                            <img src="assets/ewallet/img/operational4.png" className="center-card-img"/>
                            <h4 className="ta-center">Program Implementation & Management</h4>
                            <ul className="ml-40">
                                <li><i className="pi pi-chevron-right"></i> Full KYB of business, shareholders and directors</li>
                                <li><i className="pi pi-chevron-right"></i> Program features tailored to fit each customer</li>
                                <li><i className="pi pi-chevron-right"></i> Customers have total access to reports and clients management</li>
                            </ul>
                        </div>
                        <div className="p-col-4">
                            <img src="assets/ewallet/img/operational5.png" className="center-card-img"/>
                            <h4 className="ta-center">Loading Options</h4>
                            <ul className="ml-40">
                                <li><i className="pi pi-chevron-right"></i> Through the wallet</li>
                                <li><i className="pi pi-chevron-right"></i> By corporate load</li>
                                <li><i className="pi pi-chevron-right"></i> Payment of commissions</li>
                            </ul>
                        </div>
                        <div className="p-col-4">
                            <img src="assets/ewallet/img/operational6.png" className="center-card-img"/>
                            <h4 className="ta-center">Client Wallet Loading Options</h4>
                            <div className="p-grid">
                                <div className="p-col-6">
                                    <ul className="ml-40">
                                        <li><i className="pi pi-chevron-right"></i> VISA</li>
                                        <li><i className="pi pi-chevron-right"></i> Mastercard</li>
                                        <li><i className="pi pi-chevron-right"></i> Union Pay</li>
                                        <li><i className="pi pi-chevron-right"></i> Alipay</li>
                                        <li><i className="pi pi-chevron-right"></i> Wechat</li>
                                        <li><i className="pi pi-chevron-right"></i> Bank wire</li>
                                    </ul>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

                <div className="p-col-12"  style={{padding: 0}}>
                    <footer className="footer">
                        <h3>How do you like the sound of having your funds in the cloud?</h3>
                        <span>
                            With an EWallet business account, you will be able to load funds to the cloud,
                            make payments to other members, pay your suppliers, collect funds from clients and manage a multi-currency wallet,
                            along with many other benefits that will allow you or your finance departments to manage your company with greater ease and efficiency.
                            Your company will be part of the new era of cloud cash management and digital assets.
                        </span>
                        <div className="container">
                            <p className="copyright-text">E-Wallet International &copy; 2017-2021 All rights reserved.</p>
                        </div>
                    </footer>
                </div>
            </div>
        )
    }
}
