import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {Steps} from "primereact/steps";
import {Growl} from "primereact/growl";

export class KYC extends Component {

    constructor() {
        super();
        this.state = {
            activeProfileIndex: 0,
            stepsProfile: [
                {
                    label: 'Upload Documents',
                    command: (event) => {
                        this.growl.show({severity: 'info', summary: 'First Step', detail: event.item.label});
                    }
                },
                {
                    label: 'Review in Documents',
                    command: (event) => {
                        this.growl.show({severity: 'info', summary: 'Second Step', detail: event.item.label});
                    }
                },
                {
                    label: 'Approved Documents',
                    command: (event) => {
                        this.growl.show({severity: 'info', summary: 'Third Step', detail: event.item.label});
                    }
                }
            ],
        };
    }

    render() {
        const pendingIdentificationTable = (
            <table className="custom_table">
                <tbody>
                <tr className="head">
                    <td> File type</td>
                    <td> Requested Date</td>
                    <td> Comment</td>
                    <td> Status</td>
                    <td></td>
                </tr>

                <tr>
                    <td>
                        <a href="#" data-helper="3" data-upload="myDropZone_6" className="search_id" name="type_id_6">
                            Government ID
                        </a>
                    </td>

                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <form className="dropzone dz-clickable" id="myDropZone_6"
                              style={{width: '50%', border: 2, dashed: '#028AF4', textAlign: 'center'}}
                              encType="multipart/form-data" hidden="hidden">
                            <input type="hidden"/>
                            <input className="form-control" id="id_section" name="section" type="hidden" value="3"/>
                            <div className="dz-default dz-message"><span>Drop files here to upload</span></div>
                        </form>
                    </td>
                </tr>

                <tr>

                    <td>
                        <a href="#" data-helper="1" data-upload="myDropZone_4" className="search_id" name="type_id_4">
                            Passport
                        </a>
                    </td>

                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <form style={{width: '50%', border: 2, dashed: '#028AF4', textAlign: 'center'}}
                              encType="multipart/form-data" hidden="hidden">
                            <input type="hidden" name="csrfmiddlewaretoken" value="hPN9oZ2rUNogCWsDvpcnW03mu7MRvkxV"/>
                            <input className="form-control" id="id_section" name="section" type="hidden" value="1"/>
                            <div className="dz-default dz-message"><span>Drop files here to upload</span></div>
                        </form>
                    </td>
                </tr>

                </tbody>
            </table>
        );
        const pendingProofAddressTable = (
            <table className="custom_table">
                <tbody>
                <tr className="head">
                    <td> File type</td>
                    <td> Requested Date</td>
                    <td> Comment</td>
                    <td> Status</td>
                    <td></td>
                </tr>

                <tr>
                    <td><a href="#" name="type_id_7" data-upload="myDropZone_7" className="link-show">Utility Bill</a>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <form className="dropzone dz-clickable"
                              style={{width: '50%', border: 2, dashed: '#028AF4', textAlign: 'center'}}
                              encType="multipart/form-data" hidden="hidden">
                            <input type="hidden" name="csrfmiddlewaretoken" value="hPN9oZ2rUNogCWsDvpcnW03mu7MRvkxV"/>
                            <input className="form-control" id="id_section" name="section" type="hidden" value="4"/>
                            <div className="dz-default dz-message"><span>Drop files here to upload</span></div>
                        </form>
                    </td>
                </tr>

                <tr>
                    <td><a href="#" name="type_id_8" data-upload="myDropZone_8" className="link-show">Bank Statement</a>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>
                        <form className="dropzone dz-clickable"
                              style={{width: '50%', border: 2, dashed: '#028AF4', textAlign: 'center'}}
                              encType="multipart/form-data" hidden="hidden">
                            <input type="hidden" name="csrfmiddlewaretoken" value="hPN9oZ2rUNogCWsDvpcnW03mu7MRvkxV"/>
                            <input className="form-control" id="id_section" name="section" type="hidden" value="5"/>
                            <div className="dz-default dz-message"><span>Drop files here to upload</span></div>
                        </form>
                    </td>
                </tr>

                </tbody>
            </table>
        );

        const uploadIdentificationTable = (
            <table className="custom_table">
                <tbody>
                <tr className="head">
                    <td> File type</td>
                    <td> Requested Date</td>
                    <td> Comment</td>
                    <td> Status</td>
                </tr>
                <tr>
                    <td> Drivers License</td>
                    <td> August 08, 2019</td>
                    <td></td>
                    <td> Requested</td>
                </tr>

                </tbody>
            </table>
        );
        const uploadProofAddressTable = (
            <table className="custom_table">
                <tbody>
                <tr className="head">
                    <td> File type</td>
                    <td> Requested Date</td>
                    <td> Comment</td>
                    <td> Status</td>
                </tr>

                </tbody>
            </table>
        );

        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} style={{marginTop: '75px'}}/>
                <div className="left-side">
                    <div className="p-grid">
                        <div className="p-col-12">
                            <div className="card your-balance_card">
                                <h2 className="mb-20">Verify your Profile</h2>
                                <Steps model={this.state.stepsProfile} activeIndex={this.state.activeProfileIndex}
                                       onSelect={(e) => this.setState({activeProfileIndex: e.index})} readOnly={false}/>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="card upload-file-card ta-center">
                                <h2 className="mb-20 ta-left">Upload Picture</h2>
                                <div className="upload-btn-wrapper">
                                    <button className="uploadbtn">
                                        <img src="assets/ewallet/img/Upload_picture.png"/>
                                    </button>
                                    <input type="file" name="myfile"/>
                                </div>
                                <p>or Drop file here <br/> (up to 2MB)</p>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="card your-balance_card">
                                <h2 className="mb-20">KYC Files (Click on each document to see an example)</h2>
                                <h2>Pending Requests:</h2>
                                <h4>1. VALIDATE YOUR IDENTIFICATION</h4>
                                {pendingIdentificationTable}
                                <h4>2. PROOF OF ADDRESS</h4>
                                {pendingProofAddressTable}
                                <div className="p-col-12 p-md-4 p-offset-8 ta-right">
                                    <Button label="Search" style={{marginTop: 15}}/>
                                </div>
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="card your-balance_card">
                                <h2  className="mb-20">Uploads Files</h2>
                                <h4>1. VALIDATE YOUR IDENTIFICATION</h4>
                                {uploadIdentificationTable}
                                <h4>2. PROOF OF ADDRESS</h4>
                                {uploadProofAddressTable}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right-side-bar">
                    <div className="card your-balance_card" style={{position: 'fixed', top: 73}}>
                        <h2 className="mb-20">Document Example</h2>
                        <p className="sub-heading">Passport Scanned or Photo:</p>
                        <ul className="dash-liststyle">
                            <li><span className="dash-circle"></span> Both Pages</li>
                            <li><span className="dash-circle"></span> Clear Copy</li>
                            <li><span className="dash-circle"></span> Not Expired</li>
                        </ul>
                        <div className="document-izmage">
                            <img style={{maxJHeight: 400, maxWidth: 240}} src="assets/ewallet/img/Passeport.png"/>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
