import React, {Component} from 'react';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";

export class Active_Card extends Component {

    constructor() {
        super();
        this.state = {
            activateDisplay:false,
        };
    }

    render() {
        const dialogFooterActivate = (
            <div>
                <Button icon="pi pi-check" onClick={() => this.setState({activateDisplay:false})} label="Yes" />
                <Button icon="pi pi-times" onClick={() => this.setState({activateDisplay:false})} label="No" className="p-button-secondary" />
            </div>
        );
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h2 className="mb-20">Active Card</h2>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-3">
                                Card Number:
                                <InputText placeholder="#### #### #### ####"/>
                            </div>
                            <div className="p-col-12 p-md-3">
                                <Dialog header="Activate confirmation" visible={this.state.activateDisplay} modal={true}
                                        style={{width: '50vw'}}
                                        footer={dialogFooterActivate}
                                        onHide={() => this.setState({activateDisplay: false})}>
                                    <p style={{color: '#292B2C'}}>Activate</p>
                                </Dialog>
                                <Button label="Activate" style={{marginTop: 15}}
                                        onClick={() => this.setState({activateDisplay: true})}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
