import React, {Component} from 'react';
import {InputText} from "primereact/inputtext";

export class Faq extends Component {

    constructor() {
        super();
        this.state = {};
    }

    render() {
        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-12" style={{paddingBottom:0}}>
                    <div className="card card-w-title" style={{marginBottom:0}}>
                        <div className="p-grid">
                            <div className="p-col-12 p-md-12">
                                <h1>Help Center</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="card card-w-title">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-12">
                                <h2 className="mb-20">Getting Started</h2>
                                <ul>
                                    <li className="mb-10">What devices and platforms are supported?</li>
                                    <li className="mb-10">Where can I set up and manage my profile?</li>
                                    <li className="mb-10">How much content is available on E-Wallet International?</li>
                                    <li className="mb-10">What are the E-Wallet International beneficts?</li>
                                </ul>
                                <a>View all articles</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="card card-w-title">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-12">
                                <h2 className="mb-20">Troubleshooting</h2>
                                <ul>
                                    <li className="mb-10">Why won't my upload card?</li>
                                    <li className="mb-10">What are the Transfer Internet Speed Recommendations?</li>
                                    <li className="mb-10">Why am I getting low-quality capability?</li>
                                    <li className="mb-10">How do I clear my browser cache?</li>
                                    <li className="mb-10">How do I test my Internet connection?</li>
                                    <li className="mb-10">How do I test my Internet connection?</li>
                                </ul>
                                <a className="mb-10">View all articles</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-col-12 p-lg-4">
                    <div className="card card-w-title">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-12">
                                <h2 className="mb-20">Frequent questions</h2>
                                <ul>
                                    <li className="mb-10">Why won't my upload card?</li>
                                    <li className="mb-10">What are the Transfer Internet Speed Recommendations?</li>
                                    <li className="mb-10">Why am I getting low-quality capability?</li>
                                    <li className="mb-10">How do I clear my browser cache?</li>
                                    <li className="mb-10">How do I test my Internet connection?</li>
                                    <li className="mb-10">How do I test my Internet connection?</li>
                                </ul>
                                <a className="mb-10">View all articles</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
