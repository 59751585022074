import React, {Component} from 'react';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {TabMenu} from "primereact/tabmenu";
import {changeStyleSheetUrl} from "../util/commonFunctions";

export default class Forgot_Password extends Component {

    constructor(props) {
        super(props);

        this.items =  [
            {label: 'Why E-Wallet', icon: 'pi pi-fw pi-home'},
            {label: 'About Us', icon: 'pi pi-fw pi-calendar'},
            {label: 'Statistics', icon: 'pi pi-fw pi-pencil'},
            {label: 'Operational Capabilities', icon: 'pi pi-fw pi-file'}
        ];
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');
    }

    sendWallet = () => {
        this.props.history.push("/wallet");
    }

    render() {
        return (
            <div className="login-body">
                <div className="card" style={{marginBottom: 0}}>
                    <div className="p-grid" style={{marginBottom: 0}}>
                        <div className="p-col-2">
                            <img src="assets/ewallet/img/logo.png" onClick={this.sendWallet} className="hand-icon"/>
                        </div>
                        <div className="p-col-8">
                            <TabMenu model={this.items} style={{marginBottom: 25}}/>
                        </div>
                        <div className="p-col-2">
                            <div style={{paddingTop: 20}}>
                                <a href="#/signUp" className="wallet_a">Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card login-panel p-fluid">
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div className="p-col-3" style={{textAlign: 'left'}}>
                                <img src="assets/layout/images/login/icon-login.svg" alt="avalon-react"/>
                            </div>
                            <div className="p-col-9" style={{textAlign: 'left'}}>
                                <h2 className="welcome-text" style={{marginTop: 15}}>E-Wallet International</h2>
                                <span className="guest-sign-in">Send email for recovery password</span>
                            </div>
                            <div className="p-col-12" style={{textAlign: 'left'}}>
                                <label className="login-label">Username</label>
                                <div className="login-input">
                                    <InputText placeholder="Username"/>
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 button-pane">
                                <Button label="Send email" onClick={() => {
                                    window.location = "/#/login"
                                }}/>
                            </div>
                            <div className="p-col-12 p-md-6 button-pane">
                                <Button label="Cancel"
                                        className="p-button-secondary"
                                        onClick={() => {
                                            window.location = "/#/login"
                                        }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}