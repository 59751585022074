import React, {Component} from 'react';

export class Apply_for_Card extends Component {

    constructor() {
        super();
        this.state = {};
    }

    render() {
        return (
            <div className="p-grid dashboard">
                <div className="p-col-12 p-md-6 p-lg-4">
                    <h2 className="mb-20">Apply_for_Card</h2>
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                </div>
                <div className="p-col-12 p-md-6 p-lg-4">
                </div>
            </div>
        );
    }
}
