import React, { Component } from 'react';
import {InputText} from "primereact/inputtext";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";

import {TabMenu} from "primereact/tabmenu";
import {changeStyleSheetUrl} from "../util/commonFunctions";

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: '',
            middleName: '',
            lastName: '',
            birthday: '',
            preferredCurrency: '',
            phone: '',
            phoneType: '',

            country: '',
            countryBirth: '',
            nationality: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            _state: '',
            postCode: '',
            companyName: '',
            companyEmail: '',
            companyRegistration: '',

            catPhones: [],
            catCountries: [],
            catNationalities: [],
            catIdTypes: [],
            currencies:[{label: 'Select Currency', value: ''},
                {label: 'USD', value: 'USD'}]
        };
        this.items =  [
            {label: 'Why E-Wallet', icon: 'pi pi-fw pi-home'},
            {label: 'About Us', icon: 'pi pi-fw pi-calendar'},
            {label: 'Statistics', icon: 'pi pi-fw pi-pencil'},
            {label: 'Operational Capabilities', icon: 'pi pi-fw pi-file'}
        ];
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');
    }

    submitHandler = e => {
    };

    changeHandler = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    sendWallet = () => {
        this.props.history.push("/wallet");
    }

    render() {
        const {
            firstName,
            middleName,
            lastName,
            birthday,
            preferredCurrency,
            phone,
            phoneType,

            country,
            countryBirth,
            nationality,
            addressLine1,
            addressLine2,
            city,
            _state,
            postCode,

            catPhones,
            catCountries,
            catNationalities,
            companyName,
            companyEmail,
            companyRegistration,
        } = this.state;

        return (
            <div>
                <div className="card p-grid" style={{marginBottom: 0}}>
                    <div className="p-col-2" style={{minWidth: 140}}>
                        <img src="assets/ewallet/img/logo.png" onClick={this.sendWallet} className="hand-icon"/>
                    </div>
                    <div className="p-col-10 ta-right" style={{minWidth: 300}}>
                        <div style={{paddingTop: 20}}>
                            <a href="#/login" className="wallet_a">Demo Login</a>
                        </div>
                    </div>
                </div>
                <div className="p-grid p-fluid" style={{padding:50}}>
                    <div className="card card-w-title">
                        <div className="p-grid">
                            <div className="p-col-12">
                                <h2>Signup</h2>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>First Name</label>
                                <InputText
                                    placeholder="First Name"
                                    name="firstName"
                                    onChange={this.changeHandler}
                                    value={firstName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Middle Name</label>
                                <InputText
                                    placeholder="Middle Name"
                                    name="middleName"
                                    onChange={this.changeHandler}
                                    value={middleName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Last Name</label>
                                <InputText
                                    placeholder="Last Name"
                                    name="lastName"
                                    onChange={this.changeHandler}
                                    value={lastName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Birthday</label>
                                <InputText
                                    placeholder="yyyy-mm-dd"
                                    name="birthday"
                                    onChange={this.changeHandler}
                                    value={birthday}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Preferred Currency</label>
                                <Dropdown options={this.state.currencies}
                                          value={preferredCurrency}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Phone</label>
                                <InputText
                                    placeholder="Phone"
                                    name="phone"
                                    onChange={this.changeHandler}
                                    value={phone}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Phone Type</label>
                                <Dropdown options={catPhones}
                                          value={phoneType}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Country Birth</label>
                                <Dropdown options={catCountries}
                                          value={countryBirth}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Nationality</label>
                                <Dropdown options={catNationalities}
                                          value={nationality}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <hr className="hr_separator" />
                            <div className="p-col-12">
                                <h2>Billing Address</h2>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Address Line 1</label>
                                <InputText
                                    placeholder="Address Line 1"
                                    name="addressLine1"
                                    onChange={this.changeHandler}
                                    value={addressLine1}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Address Line 2</label>
                                <InputText
                                    placeholder="Address Line 2"
                                    name="addressLine2"
                                    onChange={this.changeHandler}
                                    value={addressLine2}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>City</label>
                                <InputText
                                    placeholder="City"
                                    name="city"
                                    onChange={this.changeHandler}
                                    value={city}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>State</label>
                                <InputText
                                    placeholder="State"
                                    name="_state"
                                    onChange={this.changeHandler}
                                    value={_state}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Post Code</label>
                                <InputText
                                    placeholder="Post Code"
                                    name="postCode"
                                    onChange={this.changeHandler}
                                    value={postCode}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Country</label>
                                <Dropdown options={catCountries}
                                          value={country}
                                          onChange={this.changeHandler}
                                          autoWidth={false} />
                            </div>
                            <div className="p-col-12">
                                <h2>Company</h2>
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Name</label>
                                <InputText
                                    placeholder="Name"
                                    name="companyName"
                                    onChange={this.changeHandler}
                                    value={companyName}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Email</label>
                                <InputText
                                    placeholder="Email"
                                    name="companyEmail"
                                    onChange={this.changeHandler}
                                    value={companyEmail}
                                />
                            </div>
                            <div className="p-col-12 p-md-4">
                                <label>Registration</label>
                                <InputText
                                    placeholder="Registration"
                                    name="companyRegistration"
                                    onChange={this.changeHandler}
                                    value={companyRegistration}
                                />
                            </div>
                            <div className="p-col-12 p-md-4 p-offset-4 mt-40">
                                <Button label="Submit" style={{marginBottom:'10px'}} onClick={this.submitHandler}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default SignUp;
