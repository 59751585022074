import React, {Component} from 'react';
import {Redirect} from 'react-router-dom';
import {InputText} from 'primereact/inputtext';
import {Button} from "primereact/button";
import {TabMenu} from "primereact/tabmenu";
import {Growl} from 'primereact/growl';

import {changeStyleSheetUrl} from "../util/commonFunctions";

export default class Login extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            usernameError: '',
            passwordError: '',
            login: false,
            errorLogin: null,
            messages: null,
            rand: Math.floor(Math.random() * (34 - 1)) + 1,
            height: window.innerHeight,
            usuario: this.datasource,
            visibleDialog: false,
            validando: false,
            redirectSignUp: false,
        }
        this.items =  [
            {label: 'Home', icon: 'pi pi-fw pi-home'},
            {label: 'About Us', icon: 'pi pi-fw pi-calendar'},
            {label: 'Statistics', icon: 'pi pi-fw pi-pencil'},
            {label: 'Operational Capabilities', icon: 'pi pi-fw pi-file'}
        ];

        this._handleKeyPress = this._handleKeyPress.bind(this);
        this.checkSession = this.checkSession.bind(this);
        this.validarLogueo = this.validarLogueo.bind(this);
    }

    componentDidMount() {
        changeStyleSheetUrl('layout-css', 'ewallet-v4', 'layout');
        changeStyleSheetUrl('theme-css', 'ewallet-v4', 'theme');
    }

    sendWallet = () => {
        this.props.history.push("/wallet");
    }

    _handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.checkSession(e);
        }
    }

    /**
     * Metodo para mostrar mensaje de error cuando el usuario y/o password son incorrectos
     * params:
     * return: se habilita mensaje de error
     **/
    showError() {
        let msg = {severity: 'error', summary: 'ERROR', detail: 'Verify user and password are correct'};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta cuando el usuario y/o password estan vacios
     * params:
     * return: se habilita mensaje de alerta
     **/
    showWarn() {
        let msg = {severity: 'warn', summary: 'AVISO', detail: 'You must specify username and password'};
        this.growl.show(msg);
    }

    /**
     * Metodo para mostrar mensaje de alerta generico
     * params:
     * return: se habilita mensaje de alerta
     **/
    showMsg(severityMsg, summaryMsg, detailMsg) {
        let msg = {severity: severityMsg, summary: summaryMsg, detail: detailMsg};
        this.growl.show(msg);
    }

    /**
     * Metodo para autenticacion del usuario
     * Se utiliza el serivicio AuthService
     * params: usurname y password
     * return: mensaje de error o acceso al sistema
     **/
    checkSession = (e) => {
        e.preventDefault();
        this.setState({validando: true})
        if (this.isValidForm()) {
            let errorLogin = true;
            this.setState({errorLogin});
            this.setState({
                username: null,
                password: null
            });
            this.validarLogueo();
        } else {
            this.setState({validando: false});
            this.showWarn();
        }
    }

    /**
     * Metodo de validacion de usuario y password no se encuentren vacios
     * params: usurname y password
     * return: true o false
     **/
    isValidForm() {
        let isValid = true;
        let usernameError = '';
        let passwordError = '';

        usernameError = this.state.username === '' ? 'El Usuario es obligatorio' : '';
        passwordError = this.state.password === '' ? 'La Contraseña es obligatorio' : '';

        isValid = usernameError === '' && passwordError === '';
        return isValid;
    }

    validarLogueo() {
        const expiration = (Date.now() / 1000) + 1000;
        sessionStorage.setItem("access_token",'Test_token');
        sessionStorage.setItem("expires_date", expiration.toString());
        sessionStorage.setItem("refresh_token",'resp.data.refresh');
        this.setState({login: true});
        sessionStorage.setItem('userNameSesion', 'User');
        sessionStorage.setItem('lstMerchants', null);
        sessionStorage.setItem('role', 'role');
        sessionStorage.setItem('phone', '5555555555');
        sessionStorage.setItem('login', true);
        sessionStorage.setItem('userLogged', 'userLogged');
        this.props.history.push("/")
        /*
        const self = this;
        self.usuarioService.getUsuarioLogueo().then(resp => {
            let lstMerchants = JSON.stringify(resp.data.profile.lstMerchants);
            let role = resp.data.profile.rol;
            let phone = resp.data.profile.cell_number;
            const userLogged = resp.data.first_name + ' ' + resp.data.last_name;
            self.setState({login: true});
            sessionStorage.setItem('userNameSesion', self.state.username);
            sessionStorage.setItem('lstMerchants', lstMerchants);
            sessionStorage.setItem('role', role);
            sessionStorage.setItem('phone', phone);
            sessionStorage.setItem('login', true);
            sessionStorage.setItem('userLogged', userLogged);
            self.props.history.push("/")
        }).catch(error => {
            console.log(error);
        });
        */
    }

    render() {
        if (this.state.login) {
            return (<Redirect to={`/`}/>);
        }

        return (
            <div className="login-body">
                <Growl ref={(el) => this.growl = el} />
                <div className="card" style={{marginBottom: 0}}>
                    <div className="p-grid" style={{marginBottom: 0}}>
                        <div className="p-col-2">
                            <img src="assets/ewallet/img/logo.png" onClick={this.sendWallet} className="hand-icon"/>
                        </div>
                        <div className="p-col-8">
                            {/*
                            <TabMenu model={this.items} style={{marginBottom: 25}}/>
                            */}
                        </div>
                        <div className="p-col-2">
                            <div style={{paddingTop: 20}}>
                                <a href="#/signUp" className="wallet_a">Sign Up</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card login-panel p-fluid">
                    <div className="login-panel-content">
                        <div className="p-grid">
                            <div className="p-col-3" style={{textAlign: 'left'}}>
                                <img src="assets/layout/images/login/icon-login.svg" alt="avalon-react"/>
                            </div>
                            <div className="p-col-9" style={{textAlign: 'left'}}>
                                <h2 className="welcome-text" style={{marginTop: 15}}>Welcome to E-Wallet International</h2>
                                <span className="guest-sign-in">Sign in</span>
                            </div>
                            <div className="p-col-12" style={{textAlign: 'left'}}>
                                <label className="login-label">Username</label>
                                <div className="login-input">
                                    <InputText placeholder="Username"
                                               id="usuario" onKeyPress={this._handleKeyPress}
                                               value={this.state.username}
                                               onChange={(e) => this.setState({username: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="p-col-12" style={{textAlign: 'left'}}>
                                <label className="login-label">Password</label>
                                <div className="login-input">
                                    <InputText type="password" placeholder="Password"
                                               id="password" onKeyPress={this._handleKeyPress}
                                               value={this.state.password}
                                               onChange={(e) => this.setState({password: e.target.value})}
                                    />
                                </div>
                            </div>
                            <div className="p-col-12 p-md-6 button-pane">
                                <Button label="Sign In"
                                        className={this.state.validando ? 'secondary-btn' : "p-button-rounded p-button-primary"}
                                        disabled={this.state.validando} onClick={this.checkSession}
                                />
                            </div>
                            <div className="p-col-12 p-md-6 link-pane">
                                <a href="/#/forgot_password">Forgot Password?</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}